import React from 'react';

import { Grid, GridProps } from '@material-ui/core';
import cx from 'classnames';
import Spinner from 'src/components/spinner/spinner';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

interface CustomGridInterface extends Omit<GridProps, 'spacing'>, SpacingProps {
  hide?: boolean;
  loading?: boolean;
}

export function CustomGrid({ children, spacing, hide, loading, ...props }: CustomGridInterface) {
  const spacingClass = useSpacing(spacing);

  if (hide) {
    return null;
  }

  return (
    <Grid {...props} className={cx(props.className, spacingClass)}>
      {loading && <Spinner />}
      {!loading && children}
    </Grid>
  );
}
