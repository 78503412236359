import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditSuspensionPoint from 'src/components/edit-suspension-point/edit-suspension-point';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const EditViolationPage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/players/:id/suspensions/violations">
      <EditViolationRoute path="/:violationId" />
    </Router>
  );
};

interface EditViolationRouteProps extends RouteComponentProps {
  id?: string;
  violationId?: string;
}

const EditViolationRoute: React.FC<EditViolationRouteProps> = ({ id, violationId }) => {
  return (
    <Layout>
      <SEO title="Edit violation" />
      <EditSuspensionPoint id={id} violationId={violationId} />
    </Layout>
  );
};

export default EditViolationPage;
