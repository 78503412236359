import graphql from 'graphql-tag';

export const CREATE_VIOLATION = graphql`
  mutation CreateViolation($violationCreateInput: ViolationCreateInput!) {
    createViolation(violationCreateInput: $violationCreateInput) {
      id
      uaid
      suspensionCode {
        code
        shortDescription
        longDescription
        points
      }
      expiryDate
      violationDate
      createdAt
      firstName
      lastName
      district
      section
      errorMessages
    }
  }
`;
export const GET_SUSPENSION_CODE = graphql`
  query GetSuspensionCodes {
    code
    shortDescription
    longDescription
    points
  }
`;

export const ARCHIVE_SUSPENSION_CODE = graphql`
  mutation deleteSuspensionCode($code: String!) {
    deleteSuspensionCode(code: $code) {
      id
    }
  }
`;

export const GET_VIOLATION = graphql`
  query GetViolation($id: String!) {
    getViolation(id: $id) {
      id
      uaid
      suspensionCode {
        code
        shortDescription
        longDescription
        points
      }
      expiryDate
      violationDate
      comment
      createdAt
      firstName
      lastName
      district
      section
      tournamentId
      tournamentName
      tournamentStartDate
      tournamentEndDate
      tournamentDirectorPhone
      tournamentDirectorComment
      tournamentDirectorFirstName
      tournamentDirectorLastName
      reporterFirstName
      reporterLastName
      isTournamentDesk
      refereeFirstName
      refereeLastName
      refereeUaid
      refereePhone
      eventName
      organizationName
      roundName
      matchUpPlayers
      matchUpResult
    }
  }
`;

export const UPDATE_VIOLATION = graphql`
  mutation UpdateViolation($violationUpdateInput: ViolationUpdateInput!, $uaid: String!, $id: String!) {
    updateViolation(violationUpdateInput: $violationUpdateInput, uaid: $uaid, id: $id) {
      id
      uaid
      suspensionCode {
        code
        shortDescription
        longDescription
        points
      }
      expiryDate
      violationDate
      comment
      createdAt
      firstName
      lastName
      district
      section
      errorMessages
    }
  }
`;

export const DELETE_VIOLATION = graphql`
  mutation DeleteViolation($id: String!, $uaid: String!) {
    deleteViolation(id: $id, uaid: $uaid) {
      id
      uaid
      suspensionCode {
        code
        shortDescription
        longDescription
        points
      }
      expiryDate
      violationDate
      createdAt
      firstName
      lastName
      district
      section
      errorMessages
    }
  }
`;
