import React, { useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useParams } from '@reach/router';
import { Field, Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import {
  DELETE_VIOLATION,
  GET_VIOLATION,
  UPDATE_VIOLATION,
} from 'src/components/add-suspension-point/violation-queries';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import { Option } from 'src/components/dropdown/dropdown';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import { DateField, DropdownField, RangeContainer, TextAreaField } from 'src/components/formik-fields/formik-fields';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import { GET_SUSPENSION_CODES, LIST_PLAYER_VIOLATIONS } from 'src/components/players/players-queries';
import { InfoLabelItem } from 'src/components/ranking-entry-helper/ranking-entry-helper';
import Spinner from 'src/components/spinner/spinner';
import { Body, H2, H3 } from 'src/components/typography/typography';
import { usePlayerByExternalId } from 'src/hooks/data/suspensions';
import { formatDate } from 'src/utils/helper/membership';
import { violationDateUTCStripTime } from 'src/utils/helper/suspension-utils';
import { NavigationState } from 'src/utils/typedefs/navigation';
import * as Yup from 'yup';

import { TextInput } from '@clubspark-react/clubspark-react-tools';

import * as styles from './edit-suspension-point.module.less';

interface InitialValues {
  violationCode: string;
  comment: string;
  points: string;
  date: Date;
}

const EditSuspensionPoint = ({ id, violationId }) => {
  const { t } = useTranslation();

  const [deleteDialog, setDeleteDialog] = useState(false);
  const params = useParams();
  const playerUUID = params?.id;
  const { data } = useQuery(GET_SUSPENSION_CODES, {
    client: meshGatewayClient,
    fetchPolicy: 'no-cache',
  });
  const { data: violationData, loading: loadingViolation } = useQuery(GET_VIOLATION, {
    client: meshGatewayClient,
    variables: { id: violationId },
  });

  const [updateViolation, { loading: updatingViolation }] = useMutation(UPDATE_VIOLATION, {
    client: meshGatewayClient,
  });

  const [deleteViolation, { loading: deletingViolation }] = useMutation(DELETE_VIOLATION, {
    client: meshGatewayClient,
    variables: {
      id: violationId,
      uaid: playerUUID,
    },
    refetchQueries: [{ query: LIST_PLAYER_VIOLATIONS, variables: { uaid: playerUUID } }],
    awaitRefetchQueries: true,
  });

  const initialValues: InitialValues = useMemo(() => {
    const { suspensionCode = {}, comment = '' } = violationData?.getViolation ?? {};
    const { code = '', shortDescription = '', points = '' } = suspensionCode;
    const dateUTC = violationDateUTCStripTime(violationData?.getViolation) ?? new Date();

    return {
      violationCode: code,
      shortDescription: shortDescription,
      comment,
      points,
      date: dateUTC,
    };
  }, [violationData]);

  const { fullName } = usePlayerByExternalId(playerUUID);

  const violationCodeOptions = getViolationCodeOptions(data?.listSuspensionCodes);

  const handleSubmit = async (values: InitialValues) => {
    const { violationCode, date, comment, points } = values;

    const input = {
      uaid: playerUUID,
      violationDate: date || violationData?.getViolation?.violationDate,
      comment: comment,
      points: parseFloat(points),
      ...(violationCode !== violationData?.getViolation?.suspensionCode?.code ? { code: violationCode } : {}),
    };
    updateViolation({
      variables: {
        violationUpdateInput: input,
        uaid: playerUUID,
        id: violationId,
      },
      onCompleted: () => {
        const state: NavigationState = {
          popup: {
            message: `${t('violation updated')}.`,
          },
        };
        navigate(`/players/${playerUUID}?tab=suspensions`, { state });
      },
    });
  };

  const handleDelete = () => {
    deleteViolation({
      onCompleted: () => {
        const state: NavigationState = {
          popup: {
            message: `${t('violation deleted')}.`,
          },
        };
        navigate(`/players/${playerUUID}?tab=suspensions`, { state });
      },
    });
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string().max(500, t('500 characters max')),
    points: Yup.string()
      .matches(/^[0-9]*$/, t('must be a number', { type: 'points' }))
      .required(t('is required', { type: 'point field' })),
  });

  const tournamentDesk = violationData?.getViolation;

  return (
    <>
      <Grid>
        <Breadcrumbs
          paths={[
            { name: t('players'), to: '/players' },
            { name: fullName, to: `/players/${playerUUID}` },
            {
              name: t('edit violation'),
              to: `/players/${id}/suspensions/violations/${violationId}`,
              active: false,
            },
          ]}
        />
        <H2 spacing={{ margins: { sm: 'top' } }}>{t('edit violation')}</H2>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <Panel>
                {loadingViolation && <Spinner />}
                {!loadingViolation && (
                  <div className={styles.padding}>
                    <H3 spacing={{ margins: { sm: 'bottom', xs: 'top' } }}>
                      {t('violation for')} {fullName || ''}
                    </H3>
                    {tournamentDesk?.isTournamentDesk && (
                      <SuspensionPointEventInfo
                        info={[
                          {
                            label: t('organisation'),
                            value: tournamentDesk?.organizationName || t('n/a'),
                          },
                          {
                            label: t('submitted by'),
                            value:
                              tournamentDesk?.reporterFirstName && tournamentDesk?.reporterLastName
                                ? tournamentDesk?.reporterFirstName + ' ' + tournamentDesk?.reporterLastName
                                : t('n/a'),
                          },
                          {
                            label: t('submitted on'),
                            value: formatDate(tournamentDesk?.createdAt, 'll') || t('n/a'),
                          },
                          {
                            label: t('referee'),
                            value:
                              tournamentDesk?.refereeFirstName && tournamentDesk?.refereeLastName
                                ? tournamentDesk?.refereeFirstName + ' ' + tournamentDesk?.refereeLastName
                                : t('n/a'),
                          },
                          {
                            label: t('referee phone'),
                            value: tournamentDesk?.refereePhone || t('n/a'),
                          },
                          {
                            label: t('tournament director'),
                            value:
                              tournamentDesk?.tournamentDirectorFirstName && tournamentDesk?.tournamentDirectorLastName
                                ? tournamentDesk?.tournamentDirectorFirstName +
                                  ' ' +
                                  tournamentDesk?.tournamentDirectorLastName
                                : t('n/a'),
                          },
                          {
                            label: t('tournament director phone'),
                            value: tournamentDesk?.tournamentDirectorPhone || t('n/a'),
                          },
                          {
                            label: t('tournament'),
                            value: tournamentDesk?.tournamentName || t('n/a'),
                          },
                          {
                            label: t('event'),
                            value: tournamentDesk?.eventName || t('n/a'),
                          },
                          {
                            label: t('round'),
                            value: tournamentDesk?.roundName || t('n/a'),
                          },
                          {
                            label: t('players and results'),
                            value:
                              tournamentDesk?.matchUpPlayers && tournamentDesk?.matchUpResult
                                ? tournamentDesk?.matchUpPlayers + ' | ' + tournamentDesk?.matchUpResult
                                : t('n/a'),
                          },
                        ]}
                      />
                    )}

                    <Grid container>
                      <Grid container direction="column">
                        <div className={styles.datepickerContainer}>
                          <Body size="md" bold spacing={{ margins: { xxs: 'bottom', md: 'top' } }}>
                            {t('violation date')}
                          </Body>
                          <RangeContainer>
                            <DateField
                              name="date"
                              datePickerProps={{
                                disabled: false,
                                popperPlacement: 'bottom',
                              }}
                            />
                          </RangeContainer>
                        </div>
                        <Body size="md" bold spacing={{ margins: { xxs: 'bottom', xs: 'top' } }}>
                          {t('suspension code')}
                        </Body>
                        <Grid container>
                          <DropdownField
                            name="violationCode"
                            placeholder={`${initialValues?.violationCode} - ${initialValues?.points} - ${initialValues?.shortDescription}`}
                            options={violationCodeOptions ?? []}
                            spacing={{ margins: { sm: 'right' } }}
                            onSelect={(o) => {
                              setFieldValue(
                                'points',
                                getViolationCodeObject(data?.listSuspensionCodes, o.value)?.points,
                              );
                            }}
                          />
                        </Grid>
                        <Grid>
                          <Body size="md" bold spacing={{ margins: { lg: 'top' } }}>
                            {t('points')}
                          </Body>
                          <Field
                            name="points"
                            disableUnderline
                            component={TextInput}
                            className={styles.pointsField}
                            value={values?.points}
                          />
                          {errors?.points && <FormErrorMessage>{errors?.points}</FormErrorMessage>}
                        </Grid>

                        <Grid container>
                          <Body size="md" spacing={{ margins: { lg: 'vertical' } }}>
                            {getViolationCodeObject(data?.listSuspensionCodes, values.violationCode)?.longDescription}
                          </Body>
                        </Grid>

                        {tournamentDesk?.isTournamentDesk && (
                          <div className={styles.tdComments}>
                            <Body size="md" bold>
                              {t('tournament director comments')}
                            </Body>
                            <Body size="md">{tournamentDesk?.tournamentDirectorComment}</Body>
                          </div>
                        )}

                        <Body size="md" bold spacing={{ margins: { xxs: 'bottom' } }}>
                          {t('comment')}
                        </Body>
                        <Grid container className={styles.textAreaContainer}>
                          <TextAreaField name="comment" height="sm" spacing={{ margins: { xs: 'bottom' } }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Panel>
              <Grid container>
                {!loadingViolation && (
                  <>
                    <Button
                      level="tertiary"
                      type="button"
                      spacing={{ margins: { sm: 'right' } }}
                      onClick={() => navigate(`/players/${id}?tab=suspensions`)}
                    >
                      {t('cancel')}
                    </Button>
                    <Button type="submit" loading={updatingViolation}>
                      {t('save violation')}
                    </Button>
                    <Button level="warningLink" type="button" onClick={() => setDeleteDialog(true)}>
                      <Icon name="md-delete" className={styles.icon} />
                      {t('delete violation')}
                    </Button>
                  </>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <CustomDialog
        title={t('delete violation')}
        open={deleteDialog}
        hideX
        content={
          <Grid container>
            <Body size="md">{t('delete violation question')}</Body>
            <CustomGrid container justify="flex-end" spacing={{ margins: { md: 'top' } }}>
              <Button
                level="tertiary"
                onClick={() => setDeleteDialog(false)}
                spacing={{ margins: { sm: 'right' } }}
                type="button"
              >
                {t('no, cancel')}
              </Button>
              <Button level="warning" onClick={handleDelete} loading={deletingViolation}>
                {t('yes, delete')}
              </Button>
            </CustomGrid>
          </Grid>
        }
        onClose={() => setDeleteDialog(false)}
      />
    </>
  );
};

interface EventInfo {
  label: string;
  value: string;
}

interface SuspensionPointEventInfoProps {
  info: EventInfo[];
}

const SuspensionPointEventInfo: React.FC<SuspensionPointEventInfoProps> = ({ info }) => {
  return (
    <div className={styles.suspensionPointEvent}>
      {info.map((i) => {
        return <InfoLabelItem label={i.label} key={i.value} value={i.value} styleOverride={styles.infoLabelOverride} />;
      })}
    </div>
  );
};

export default EditSuspensionPoint;

const getViolationCodeOptions = (codes): Option[] => {
  return codes
    ?.map((code) => ({
      label: `${code.code} - ${code.shortDescription} - ${code.points}`,
      value: code.code,
    }))
    ?.sort((a, z) => a?.label?.localeCompare(z?.label));
};

const getViolationCodeObject = (codes, code: string) => codes?.find((c) => c.code === code) ?? '';
